import { Container, Typography } from '@mui/material'
import { MainContent, Toolbar } from './styles'

const MainContainer = (ChildComponent) => (props) => {
    return (
        <MainContent>
            <Toolbar />
            <Container>
                <Typography variant='h2' align='center' marginBottom={2}>News Peanut</Typography>
                <Typography variant='h6' align='center' marginBottom={6}>All Articles</Typography>
            </Container>
            <ChildComponent {...props} />
        </MainContent>
    )
}

export default MainContainer