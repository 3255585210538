import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Footer, Navbar } from "./components";
import { MultiplePostsPage, SinglePostPage } from './views'

const App = () => {
    return (
        <Router>
            <div>
                <Navbar />
                <Routes>
                    <Route path='/' element={<MultiplePostsPage />} />
                    <Route path="/:slug" element={<SinglePostPage />} />
                </Routes>
                <Footer />
            </div>
        </Router>
    );
}

export default App;
