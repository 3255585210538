import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Pagination } from '@mui/material'

import { reassign } from '../../features/posts/posts'
import { Posts } from '../../components'
import { getPosts } from '../../api'
import { PaginationContainer } from './styles'
import { MainContainer } from '../../containers'
import { getPageCount, shuffleArray } from '../../util/list'

const MultiplePostsPage = () => {
    const posts = useSelector((state) => state.posts.value)
    const [pageCount, setPageCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);

    const dispatch = useDispatch()

    const handlePageChange = (_, newPage) => {
        setCurrentPage(newPage);
    }

    useEffect(() => {
        const fetchData = async () => {
            const newPosts = await getPosts();
            let resultArray = [];

            for (const item of newPosts) {
                resultArray.push(item, item, item, item, item);
            }

            resultArray = shuffleArray(resultArray);
            setPageCount(getPageCount(resultArray.length))

            dispatch(reassign(resultArray))
        }
        fetchData()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps


    const InnerComponent = () => (<>
        <Posts posts={posts} page={currentPage} />
        <PaginationContainer >
            <Pagination count={pageCount} showFirstButton showLastButton page={currentPage} onChange={handlePageChange} />
        </PaginationContainer>
    </>)

    const WrappedComponent = MainContainer(InnerComponent)

    return (
        <WrappedComponent />
    )
}

export default MultiplePostsPage