import React, { useState } from 'react';
import { CardContent, Typography, IconButton } from '@mui/material';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';

import { RootCard, StyledMediaCard, StyledCardContent, StyledCardActions } from './styles';
import { formatDateToMonthDay } from '../../../util/date'
import { Link } from 'react-router-dom';

const Post = ({ post }) => {
    const [hover, setHover] = useState(false);

    const toggleViewActionButtons = () => {
        setHover(!hover)
    }

    // Building Layout
    return (
        <Link to={'/' + post.slug.current} style={{ textDecoration: 'none' }}>
            <RootCard onMouseEnter={toggleViewActionButtons} onMouseLeave={toggleViewActionButtons}>
                <div>
                    <StyledMediaCard image={post.mainImage.asset.url} title={post.name} />
                    <CardContent>
                        <StyledCardContent>
                            <Typography variant="h6" gutterBottom>
                                {post.title}
                            </Typography>
                            <Typography variant="body2">
                                {formatDateToMonthDay(post._createdAt)}
                            </Typography>
                        </StyledCardContent>
                        <Typography dangerouslySetInnerHTML={{ __html: post.description }} variant="body2" color="textSecondary" />
                    </CardContent>
                </div>
                <StyledCardActions disableSpacing style={{ visibility: hover ? '' : 'hidden', opacity: hover ? '100' : '0' }}>
                    <IconButton aria-label="add to favorites">
                        <FavoriteIcon />
                    </IconButton>
                    <IconButton aria-label="share">
                        <ShareIcon />
                    </IconButton>
                </StyledCardActions>
            </RootCard>
        </Link>
    )
};

export default Post;