export const shuffleArray = (array) => {
    const shuffledArray = [...array]; // Create a copy of the array
    for (let i = shuffledArray.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
    }
    return shuffledArray;
}

export const getPageCount = (numPosts) => {
    return Math.ceil(numPosts / 12);
}

export const getPagedArray = (array, page) => {
    const startIndex = Math.min(12 * (page - 1), array.length)
    const endIndex = Math.min(12 * page, array.length)

    return array.slice(startIndex, endIndex)
}