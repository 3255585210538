import { Card, CardActions, CardMedia } from '@mui/material';
import { styled } from '@mui/material/styles';

export const RootCard = styled(Card)(({ theme }) => ({
    maxWidth: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    cursor: 'pointer',

    transition: 'transform 0.3s ease-out',
    '&:hover': {
        transform: 'scale(1.05)',
    }
}))

export const StyledMediaCard = styled(CardMedia)(({ theme }) => ({
    height: 0,
    paddingTop: '56.25%', // 16:9
}))

export const StyledCardContent = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
}))

export const StyledCardActions = styled(CardActions)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'flex-end',
    position: 'relative',
    bottom: '0',
    opacity: '0',

    transition: 'opacity 0.5s ease-out'
}))
