export const formatDateToMonthDay = (dateString) => {
    // Create a new Date object from the input date string
    const date = new Date(dateString);

    // Array of month names
    const monthNames = [
        "January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];

    // Get the month (0-indexed) and day from the date
    const monthIndex = date.getMonth();
    const day = date.getDate();

    // Format the output string
    const formattedDate = `${day} ${monthNames[monthIndex]}`;

    return formattedDate;
}