import { Box } from '@mui/material'
import { styled } from '@mui/material/styles'

export const MainContent = styled('main')(({ theme }) => ({
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
}))

export const Toolbar = styled('div')(({ theme }) => theme.mixins.toolbar)

export const PaginationContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    marginTop: '5rem'
}))