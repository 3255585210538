import { Card, CardHeader, CardContent, CardMedia, CardActions, Avatar, Typography, IconButton, Divider } from '@mui/material'
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import { PortableText } from '@portabletext/react'

import { formatDateToMonthDay } from '../../util/date'

const SinglePost = ({ post }) => {
    return (
        <Card>
            <CardHeader
                avatar={<Avatar>{post.author[0].name[0]}</Avatar>}
                title={post.author[0].name}
                subheader={formatDateToMonthDay(post._createdAt)}
            />
            <CardContent>
                <Typography variant='h4'>{post.title}</Typography>
                <Typography variant='subheading1'>{post.description}</Typography>
            </CardContent>
            <Divider light />
            <CardMedia
                component="img"
                image={post.mainImage.asset.url}
                alt={post.title}
            />
            <Divider light />
            <CardContent>
                <PortableText
                    value={post.body}
                />
            </CardContent>
            <Divider light />
            <CardActions disableSpacing>
                <IconButton aria-label="like">
                    <FavoriteIcon />
                </IconButton>
                <IconButton aria-label="share">
                    <ShareIcon />
                </IconButton>
            </CardActions>
        </Card>
    )
}

export default SinglePost