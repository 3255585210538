import { CircularProgress } from "@mui/material"
import { StyledLoading } from "./styles"

const Loading = ({ size = 80 }) => {
    return (
        <StyledLoading>
            <CircularProgress size={size} />
        </StyledLoading>
    )
}

export default Loading