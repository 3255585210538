import React, { useEffect } from "react";
import { Grid } from "@mui/material";

import Post from './Post/Post'
import { Loading } from '../../components'
import { getPagedArray } from "../../util/list";
import { useState } from "react";

const Posts = ({ posts, page }) => {
    const [pagedPosts, setPagedPosts] = useState([])

    useEffect(() => {
        const newPagedPosts = getPagedArray(posts, page)
        setPagedPosts(newPagedPosts)
    }, [posts, page])

    // Loading
    if (!posts.length) {
        return (
            <Loading />
        )
    }

    // Building Layout
    return (
        <Grid container justify="center" spacing={4}>
            {pagedPosts.map((post, index) => (
                <Grid item key={index} xs={12} sm={6} ms={4} lg={3}>
                    <Post post={post} />
                </Grid>
            ))}
        </Grid>

    )
}

export default Posts;