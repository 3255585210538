import { Typography } from "@mui/material";
import { StyledContainer } from "./styles";

const NotFound = () => {
    return (
        <StyledContainer>
            <Typography variant="h1" color="primary">
                404
            </Typography>
            <Typography variant="h5" color="secondary">
                Page Not Found
            </Typography>
        </StyledContainer>
    );
}

export default NotFound;