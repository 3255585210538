import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Grid } from '@mui/material'

import { Loading, NotFound, SinglePost } from '../../components'
import { MainContainer } from '../../containers'
import { getPost } from '../../api'
import { PageContainer } from './styles'


const SinglePostPage = () => {
    const { slug } = useParams();
    const [post, setPost] = useState(undefined)

    useEffect(() => {
        const fetchPostData = async () => {
            const postData = await getPost(slug);
            console.log(postData)
            if (postData === null || postData.length <= 0 || postData.length > 1)
                setPost(null)
            else
                setPost(postData[0])
        }
        fetchPostData();
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const InnerComponent = () => {
        if (typeof post === "undefined")
            return (<Loading />)

        if (post === null)
            return <NotFound />

        return (
            <PageContainer>
                <Grid container justify="center" spacing={4}>
                    <Grid item xs={12} sm={12} ms={10} lg={8}>
                        <SinglePost post={post} />
                    </Grid>
                    <Grid item xs={12} sm={12} ms={2} lg={4}>
                        <div>Top Stories</div>
                    </Grid>
                </Grid>
            </PageContainer>
        )
    }

    const WrappedComponent = MainContainer(InnerComponent)
    return (
        <WrappedComponent />
    )
}

export default SinglePostPage