import sanityClient from "@sanity/client"

const sanityToken = process.env.REACT_APP_SANITY_STUDIO_ACCESS_TOKEN
const dataset = process.env.REACT_APP_SANITY_STUDIO_DATASET
const projectId = process.env.REACT_APP_SANITY_STUDIO_PROJECT_ID

const client = sanityClient({
    projectId: projectId,
    dataset: dataset,
    token: sanityToken
})

export const getPosts = async () => {
    try {
        return await client.fetch(`*[_type == "post"]{
            title,
            slug,
            mainImage{
                asset->{
                    _id,
                    url
                },
                alt
            },
            body,
            author,
            description,
            _createdAt,
        }`)
    } catch (error) {
        console.log(error)
        return []
    }
}

export const getPost = async (slug) => {
    try {
        return await client.fetch(`*[_type == "post" && slug.current == "${slug}"]{
            title,
            slug,
            mainImage{
                asset->{
                    _id,
                    url
                },
                alt
            },
            body,
            "author": *[_type == "author" && ^.author._ref == _id],
            description,
            _createdAt,
        }`)
    } catch (error) {
        console.log(error)
        return null
    }
}